.navbar_custom{
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0px;
  z-index: 4;
  width: 100%;
  height: 60px;
  overflow: hidden;
  transition:.2s ease-out;
  background-color: transparent;
  line-height: 60px;
  pointer-events: none;
  background-color: var(--color-pink);
  box-shadow: var(--shadow-standard);
}
.navbar_custom--scrolled{
  background-color: rgba(0,0,0, 1);
}
.navbar_option{
  pointer-events: auto;
}
.navbar_title {
  color: var(--color-black);
  font-size: var(--font-size--medium);
  transition:.2s ease-out;
  width: 100%;
  height: 100%;
  padding: 0px 14px;
  text-transform: uppercase;
  pointer-events: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.navbar_title:hover {
  color: var(--color-purple);
}
.navbar_title--scrolled {
  color: var(--color-pink);
}
.navbar_title--scrolled:hover {
  color: var(--color-purple);
}
.navbar_icon{
  color: var(--color-black);
  font-size: 1.5rem;
  transition:.2s ease-out;
}
.navbar_icon__object {
  width: 100%;
  height: 100%;
  padding: 0px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.navbar_option:hover .navbar_icon{
  fill: var(--color-purple);
}
.navbar_icon--scrolled {
  fill: var(--color-pink);
}
.navbar_icon--scrolled:hover {
  fill: var(--color-purple);
}
.navbar_custom--stream .navbar_icon,
.navbar_custom--stream .navbar_title {
  color: var(--color-pink);
  fill: var(--color-pink);
}
.navbar_custom--stream {
  height: 40px;
  background-color: #16191e;
}
.navbar_custom--stream .navbar_title {
  font-size: 16px;
}
.navbar_custom--stream .navbar_option {
  line-height: 40px;
}
.navbar_custom--stream .navbar_icon {
  font-size: 16px;
}
@media screen and (max-width: 768px){
  .navbar_icon {
    display: none;
  }
}

