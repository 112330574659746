.scroll_top_bar__wrapper {
  width: 100%;
  height: 90px;
  background-color: var(--color-black-opaque);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15rem 0px;
  margin-top: 50px;
  position: relative;
}
.scroll_top_bar__wrapper--stream {
  display: none;
}
.scroll_top_bar__button {
  fill: var(--color-pink);
  transition: .2s ease-in;
  cursor: pointer;
  padding: 0px 1rem;
  height: 9vw;
}
.scroll_top_bar__wrapper:hover .scroll_top_bar__button {
  fill: var(--color-purple);
}
.footer__text {
  position: absolute;
  right: 2rem;
  top: 2rem;
  color: var(--color-pink);
  text-align: right;
  padding: var(--container-spacing);
  box-sizing: border-box;
  font-size: .9em;
}
@media screen and (max-width: 992px) {
  .footer__text {
    right: 1rem;
    top: 1rem;
  }
}