.history_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.history_container {
  width: 83.33333333%;
  padding: 32px;
  background-color: var(--color-black-opaque);
  box-sizing: border-box;
  color: #ddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.history_body__section {
  margin-bottom: 50px;
}
.history_body__half {
  width: calc(50% - 24px);
}
.history_image__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

}
.history_image {
  width: 100%;
  max-width: 500px;
  align-self: flex-start;
}
.history_body__title {
  font-size: var(--font-size--medium);
}
.history_container a {
  color: var(--color-pink);
  transition:.2s ease-out;
}
.history_container a:hover {
  color: var(--color-purple);
}
@media screen and (max-width: 992px) {
  .history_container {
    flex-direction: column;
  }
  .history_body__half {
    width: 100%;
    text-align: center;
  }
}
