.contact_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0px;
  min-height: 50vh;
  box-sizing: border-box;
}
.contact_container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  padding: 2rem;
  background-color: rgba(255,255,255, .9);
  color: #000;
  box-sizing: border-box;
  box-shadow: var(--shadow-standard);
}
.contact_container_half {
  width: calc(50% - 24px);
}
@media screen and (max-width: 992px) {
  .contact_container_half {
    width: 100%;
    margin-bottom: 50px;
  }
  .contact_container {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .contact_wrapper {
    padding: 0 1rem 1rem 1rem;
  }
  .contact_container {
    width: 100%;
    padding: 1rem;
  }
}