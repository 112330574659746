.faq__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.faq__container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: rgba(231,252,248,.8);
  color: #000;
  padding: 2rem;
  box-shadow: var(--shadow-standard);
}
.faq__row {
  margin-bottom: 2rem;
}
.faq__row a {
  text-decoration: none;
  color: var(--color-purple);
}
.faq__row a:hover {
  color: var(--color-pink);
}
.faq_question {
  font-size: var(--font-size--large);
  margin-bottom: .5rem;
}

@media screen and (max-width: 768px) {
  .faq__wrapper {
    padding: 0 1rem 1rem 1rem;
  }
  .faq__container {
    width: 100%;
    padding: 1rem;
  }
}