.splash_overlay{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  pointer-events: none;
  transition: .4s ease-in;
}
.splash_image {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0px;
  left: 0px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-attachment: fixed;
  z-index: 2;
  transition: opacity .1s ease;
  opacity: 0;
}
.fade_in {
  opacity: 1;
}
.splash_image--mobile {
  background-attachment: scroll;
}
@media screen and (max-width: 992px) {
  .splash_image--left {
    background-position: -50px 50px;
  }
}

.splash_placeholder{
  width:100%;
  height: 100vh;
  position: relative;
}
.splash_header_container {
  box-sizing: border-box;
  max-width: calc(100vw - 160px);
  text-align: left;
  margin-bottom: 1.5em;
  color: #000;
  z-index: 3;
  padding-left: 40px;
  letter-spacing: 2px;
  font-size: var(--font-size--medium);
  position: relative;
  margin-bottom: 18vw;
}
.splash_header_container:before {
  content: '';
  height: 0px;
  width: 100%;
  position: absolute;
  left: 0;
  top: -1.5rem;
  border-top: calc(6vw + 4rem) solid var(--color-pink);
  border-right: 150px solid transparent;
}
.splash_header{
  z-index: 4;
}
.main_block{
  width:100%;
  background-color: #f6f5f3;
  z-index:3;
  position:relative;
}
.splash_title{
  font-size: 6vw;
  letter-spacing: 0px;
  text-transform: uppercase;
}

@media screen and (max-width: 992px) {
  .splash{
    background-attachment: scroll;
  }
  .splash_title {
    font-size: 36px;
  }
  .splash_header_container {
    font-size: 16px;
    padding: 50px;
    max-width: none;
  }
  .splash_header_container:before {
    display: none;
  }
  .splash_header_container {
    width: 100%;
    background-color: var(--color-pink);
  }
  .splash_header_container{
    position: absolute;
    top: calc(50vh - 4rem);
    display: flex;
    justify-content: center;
  }
}



.iron-image-preload {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
}
