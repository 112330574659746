/*======================================
=                PAGE                 =
======================================*/
.twitch_show_live {
  position: fixed;
  color: #fff;
  left: 30px;
  bottom: 30px;
  background-color: var(--color-black-opaque);
  border-radius: .5em;
  cursor: pointer;
  z-index: 100;
  transition:var(--transition-medium);
}
.twitch_show_live--stream {
  display: none;
}
.twitch_show_live__link {
  color: #fff;
}
.twitch_show_live__object {
  padding: 1.5rem;
}
.twitch_show_live:hover {
  background-color: #4b367c;
}
.twitch_show_offline {
  position: fixed;
  left: 30px;
  bottom: 30px;
  padding: 1.5em;
  background-color: var(--color-black-opaque);
  border-radius: .5em;
  z-index: 100;
}
.page__wrapper {
  transition: var(--transition-medium);
}
/*
.page__wrapper--blur {
  filter: blur(6px);
}
*/
@media screen and (max-width: 768px) {
  .twitch_show_live {
    display: none;
  }
}
/*======================================
=               LOAD IN               =
======================================*/
.load_in_object {
  opacity: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.load_in_object--display {
  animation: LoadObject .4s linear;
  animation-fill-mode: both;
  opacity: 1;
}
@keyframes LoadObject {
  0% {
    transform: scale(.95,.95) translateY(50px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1, 1) translateY(0px);
  }
}
@media screen and (max-width: 768px) {
  .load_in_object {
    opacity: 1;
    animation: none;
  }
}

/*======================================
=               PRELOAD               =
======================================*/
.preload_images {
  position: absolute;
  width: 0;
  height: 0;
  overflow:hidden;
}

/*======================================
=               SOCIAL                =
======================================*/
.home_social_row__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 90px;
}
.home_social_row {
  display: flex;
  flex-direction: row;
  width: calc(100% - 48px);
  max-width: 80rem;
  justify-content: space-between;
}
@media screen and (max-width: 992px) {
  .home_social_row {
    flex-direction: column;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .home_social_row {
    padding: 0 1rem;
  }
}
.social__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30rem;
  overflow-y: auto;
}
.social__wrapper a {
  color: var(--color-pink);
  font-weight: 700;
}
.social__wrapper a:hover {
  color: var(--color-purple);
}

.social__column {
  position: relative;
  margin: 0 1rem;
  flex: 1;
  overflow: hidden;
}
.social__column:hover .explore_text_block__topper:after{
  opacity: 0;
}
.social__icon {
  position: absolute;
  font-size: 12rem;
  right: 0px;
  top: -8rem;
  color: var(--color-purple);
  transition: var(--transition-medium);
  z-index: 2;
  opacity: .7;
}
.social__selector {
  height: 30rem;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12rem;
  fill: var(--color-pink);
  transition: var(--transition-medium);
  cursor: pointer;
  background-color: var(--color-black-opaque);
  transform: translateY(0);
  z-index: 3;
}
.social__selector svg {
  height: 12rem;
  width: 100%;
}
.social__selector:hover {
  fill: var(--color-purple);
}
.social__selector--display {
  transform: translateY(100%);
}
.social__label:hover .social__icon {
  fill: var(--color-pink);
}
.social__container {
  height: 0px;
  font-size: var(--font-size--small);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-content: center;
  transition: .4s ease-out;
}
.social__wrapper--display .social__container {
  height: 600px;
}
@media screen and (max-width: 992px) {
  .home_social_row {
    margin-bottom: 20px;
    align-items: center;
  }
  .social__column {
    width: 66.666666666%;
    margin-bottom: 24px;
  }
  .social__container {
    height: 600px;
  }
  .social__wrapper {
    flex-direction: column;
    width: 100%;
    overflow: visible;
    height: auto;
  }
  .social__wrapper .social__container {
    height: auto !important;
  }
  .social__icon {
    position: relative;
    font-size: 3em;
    top: 0px;
  }
  .social__selector {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .social__column {
    width: 100%;
  }
}
/*======================================
=               TWITTER               =
======================================*/
.twitter_header {
  font-size: 24px;
  text-align: center;
  background-color: var(--color-black-opaque);
  padding: 1rem;
  border-bottom: 1px solid var(--color-pink-light);
  box-sizing: border-box;
}
.twitter_header a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.social__container a {
  fill: var(--color-pink);
}
.social__container a:hover {
  fill: var(--color-purple);
}
.twitter_object {
  display: flex;
  flex-direction: column;
  background-color: var(--color-black-opaque);
  color: var(--color-pink);
  border-bottom: 1px solid var(--color-pink-light);
  box-sizing: border-box;
  padding: 1rem;
}
.twitter_object__image_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: .5rem 0px;
  overflow: hidden;
}
.twitter_object__image {
  width: 100%;
  height: auto;
  align-self: center;
  cursor: pointer;
  transition: var(--transition-medium);
}
.twitter_object__image:hover {
  transform: scale(1.03, 1.03);
}
.twitter_outlink__container {
  display: inline-block;
}
.twitter_object__bottom {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  margin-top: 1rem;
}
.twitter_object__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.twitter_object__action {
  padding: 0px 6px;
}
.twitter_text {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1rem;
}
.twitter_object svg {
  height: 16px;
}
.twitter_icon {
  margin-right: 6px;
}

@media screen and (max-width: 992px) {
  .twitter_object {
    margin-bottom: 0px;
    border-bottom: 1px solid var(--color-pink);
  }
  .twitter_header {
    margin-bottom: 0px;
    border-bottom: 1px solid var(--color-pink);
  }
  .twitter_time {
    line-height: 2rem;
  }
  .twitter_object__action {
    font-size: 1.5rem;
  }
}
/*======================================
=               YOUTUBE               =
======================================*/
.youtube_object {
  display: flex;
  flex-direction: column;
  background-color: var(--color-black-opaque);
  color: var(--color-pink);
  border-bottom: 1px solid var(--color-pink-light);
  box-sizing: border-box;
  padding: 1rem;
}
.youtube_object__image_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 25px 0px;
}
.youtube_image__wrapper {
  overflow: hidden;
  position: relative;
}
.youtube_object__image {
  width: 50%;
  height: auto;
  align-self: center;
}
.youtube_image {
  width: 100%;
  transition: var(--transition-medium);
}
.youtube_image:hover {
  transform: scale(1.03, 1.03);
}
.youtube_title {
  color: #000;
}
@media screen and (max-width: 992px) {
  .youtube_object {
    margin-bottom: 0px;
    border-bottom: 1px solid var(--color-pink);
  }
}
/*======================================
=               TWITCH               =
======================================*/
.twitch_object {
  position: relative;
  border-bottom: 1px solid var(--color-pink-light);
  box-sizing: border-box;
  background-color: var(--color-black-opaque);
  color: var(--color-pink);
  padding: 1rem;
}
.twitch_text {
  margin-top: 1rem;
  box-sizing: border-box;
}
.twitch_image {
  width: 100%;
  height: 100%;
  transition: var(--transition-medium);
  object-fit: cover;
  display: block;
}
.twitch_image:hover {
  transform: scale(1.03, 1.03);
}
.twitch_image__wrapper {
  overflow: hidden;
  position: relative;
}
.twitch_header {
  font-size: 36px;
  display: flex;
  justify-content: center;
}
.twitch_subtext {
  font-size: 13px;
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 992px) {
  .twitch_object {
    margin-bottom: 0px;
    border-bottom: 1px solid var(--color-pink);
  }
}
/*======================================
=               EXPLORE                =
======================================*/
.explore_image__wrapper {
  overflow: hidden;
  height: 20vw;
  text-align: center;
}
.explore_image {
  width: 100%;
  height: 20vw;
  object-fit: cover;
  transition: var(--transition-medium);
}
.explore_text_block:hover .explore_image {
  transform: scale(1.03, 1.03);
}
.explore__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.explore_text {
  display: flex;
  flex-direction: row;
  max-width: 80rem;
  width: 100%;;
  flex: 1;
}
.explore_text_block {
  margin: 0 1rem;
  box-sizing: border-box;
  font-size: var(--font-size--medium);
  width: 33.333333333%;
  box-shadow: var(--shadow-standard);
}
.explore_text_block__topper {
  height: 32px;
  width: 100%;
  background-color: var(--color-purple);
  position: relative;
  cursor: pointer;
  z-index: 3;
}
.explore_text_block__topper:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-pink);
  transition: var(--transition-medium);
}
.explore_text_block:hover .explore_text_block__topper:after{
  opacity: 0;
}
.explore_text_block__object {
  background-color: var(--color-black-opaque);
  padding-bottom: 1rem;
  transition: var(--transition-medium);
}
.explore_banner {
  flex: 2;
  height: 50vh;
  width: 100%;
  box-shadow: var(--shadow-standard);
}
.explore_banner__inner {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background-image: url(/static/images/2.jpg);
  background-repeat: no-repeat;
  background-position: center center;
}
.explore_banner__inner--mobile {
  background-attachment: scroll;
}
.explore_subtitle {
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 14px;
  color: var(--color-pink);
  letter-spacing: 1px;
  text-transform: uppercase;
  transition:var(--transition-medium);
  padding: 0 1rem;
}
.explore_link {
  color: var(--color-pink);
  transition:var(--transition-medium);
  padding: 0 1rem;
}
.explore_text_block__object:hover .explore_link,
.explore_text_block__object:hover .explore_subtitle {
  color: var(--color-purple);
}

@media screen and (max-width: 992px) {
  .explore_image {
    width: 50%;
  }
  .explore_image__wrapper {
    height: auto;
  }
  .explore_text {
    flex-direction: column;
    margin: 0px;
    align-items: center;
  }
  .explore_image {
    height: auto;
  }
  .explore_text_block {
    width: 66.666666666%;
  }
}
@media screen and (max-width: 768px) {
  .explore_image {
    width: 100%;
  }
  .explore_text {
    padding: 0 1rem;
    box-sizing: border-box;
  }
  .explore_text_block {
    width: 100%;
    margin: 0 0 1rem 0;
  }
}
/*======================================
=              DIVIDER                =
======================================*/
.divider {
  display: flex;
  width: 100%;
  align-items: center;
  height: 120px;
  font-size: var(--font-size--large);
  letter-spacing: 0px;
  text-transform: uppercase;
}
.divider:before,
.divider:after {
  content: '';
  background: #000;
  flex: 1;
  height: 2px;
  margin: 6px;
}
@media screen and (max-width: 992px) {
  .divider {
    font-size: var(--font-size--medium);
    height: 100px;
  }
}

/*======================================
=              EQUIPMENT                =
======================================*/
.equipment__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 100px 0;
}
.equipment_notice {
  margin-top: 4rem;
  max-width: 1000px;
}
.equipment__container {
  width: 100%;
  max-width: 1000px;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}
.equipment__label {
  display: flex;
  justify-content: center;
  font-size: var(--font-size--large);
  align-items: center;
  width: 20%;
  text-align: center;
  flex-direction: column;
}
.equipment__images {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}
.equipment_object__wrapper {
  width: 16vw;
  height: 16vw;
  padding: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-small);
  position: relative;
}
.equipment_object__wrapper .load_in_object {
  overflow: hidden;
  box-shadow: var(--shadow-standard);
}
.equipment_object__text {
  position: absolute;
  padding: 16px;
  background-color: var(--color-black-opaque);
  bottom: 0px;
  left: 0px;
  pointer-events: none;
  opacity: 0;
  transition: var(--transition-medium);
  width: 100%;
  box-sizing: border-box;
  transform: translateY(20px);
}
.equipment_object__wrapper:hover .equipment_object__text {
  opacity: 1;
  transform: translateY(0px);
}
.equipment_object__link {
  height: 100%;
  width: 100%;
  color: var(--color-purple);
}
.equipment_object__label {
  font-weight: 600;
}
.equipment_object__object {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
}
.equipment_object__image {
  width: 100%;
  object-fit: contain;
  transition: var(--transition-medium);
}
.equipment_object__object:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  /*background-color: rgba(185,103,255,.07);*/
  background-color: rgba(0,226,186,.07);
  top: 0px;
  left: 0px;
}
.equipment_object__image:hover {
  transform: scale(1.03, 1.03);
}

@media screen and (max-width: 992px) {
  .equipment__container {
    flex-direction: column;
  }
  .equipment__label {
    width: 100%;
    flex-direction: row;
  }
  .equipment__images {
    width: 100%;
  }
  .equipment_object__image:hover {
    transform: none;
  }
  .equipment_object__wrapper {
    width: 33.33333333333%;
    height: auto;
  }
  .equipment_object__wrapper .load_in_object {
    overflow: visible;
  }
}

@media screen and (max-width: 768px) {
  .equipment_object__text {
    position: relative;
    opacity: 1;
    transform: none;
    pointer-events: auto;
  }
  .equipment_object__wrapper {
    width: 50%;
    height: 30vh;
    margin-bottom: 80px;
  }
  .equipment__label {
    font-size: 48px;
  }
}
@media screen and (max-width: 415px) {
  .equipment_object__wrapper {
    width: 100%;
    padding: 1rem;
  }
  .equipment__label {
    font-size: 36px;
  }
}

.calendar__wrapper {
  display: flex;
  justify-content: center;
}
.rbc-toolbar {
  margin-bottom: 0px !important;
}
.rbc-btn-group:nth-child(3) {
  display: none !important;
}
.rbc-toolbar-label {
  text-align: right !important;
}
.rbc-calendar {
  width: 1200px !important;
  max-width: calc(100vw - 40px) !important;
  height: 800px !important;
  border: 1px solid var(--color-pink);
}
.rbc-date-cell {
  text-align: center !important;
  padding: .5rem 0px !important;
}
.rbc-event {
  /*background-color: var(--color-pink) !important;*/
  color: var(--color-black) !important;
}
@media screen and (max-width: 1200px) {
  .rbc-calendar {
    height: 80vw !important;
  }
}

.home_collections__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 6px;
  box-sizing: border-box;
  flex-direction: column;
}
.home_collections__container {
  position: relative;
  width: 100%;
  max-width: 80rem;
  overflow: hidden;
  box-shadow: var(--shadow-standard);
}
.home_collections__image__container {
  height: 55vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.home_collections__image__container:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .7;
  top: 0;
  left: 0;
  z-index: 2;
}
.home_collections__image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  transition: var(--transition-medium);
  transition: opacity .4s ease-in;
}
.home_collections__image--mobile {
  background-attachment: scroll;
}
.home_collections__container:hover .explore_text_block__topper:after{
  opacity: 0;
}
.home_collections__body {
  z-index: 3;
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  background-color: var(--color-black-opaque);
  color: var(--color-pink);
  padding: 32px;
  font-size: var(--font-size--medium);
  transition: var(--transition-medium);
  text-align: center;
  box-sizing: border-box;
  opacity: 0;
}
.home_collections__container:hover .home_collections__body {
  transform: translateY(-20px);
  opacity: 1;
}
.home_collections__container:hover .home_collections__body {
  color: var(--color-purple);
}
.home_collections__overlay_title {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-pink);
  top: 0;
  left: 0;
  font-size: var(--font-size--large);
  z-index: 3;
  transition: var(--transition-medium);
}
.home_collections__container:hover .home_collections__overlay_title {
  opacity: 0;
}