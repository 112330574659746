/*======================================
=              MENU PANEL              =
======================================*/
.twelve_menu_panel {
  position: fixed;
  width: 33.3333333333%;
  height: 100%;
  top: 0px;
  right: -33.3333333333%;
  background-color: rgba(11, 11, 11, 1);
  transition:.4s ease-out;
  z-index: 5;
}
.twelve_menu_panel--display {
  transform: translateX(-100%);
}

.twelve_menu_panel__links_wrapper {
  height: 100%;
  pointer-events:none;
  z-index: 6;
  text-transform: uppercase;
  font-size: var(--font-size--medium);
}
.twelve_menu_panel__links_wrapper--display {
  pointer-events:auto;
}
@media screen and (max-width: 768px){
  .twelve_menu_panel {
    width: 100%;
    right: -100vw;
  }
  .twelve_menu_panel--display {
    transform: translateX(-100vw);
  }
  .twelve_menu_panel__links_wrapper {
    width: 100%;
    font-size: 24px;
  }
}
/*======================================
=              MENU LINKS              =
======================================*/
.twelve_menu_panel__links {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  transition:.25s ease;
  padding: 0px 40px;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
}
.twelve_menu_panel__links--display {
  opacity: 1;
  pointer-events: auto;
}
.twelve_menu_panel__sect {
  display: flex;
  justify-content: left;
  margin: 6px 0px;
}
.twelve_menu_panel__links--display .twelve_menu_panel__sect {
  animation: TwelveFadeIn .3s linear;
  animation-fill-mode: both;
}
.twelve_menu_panel__links--display .twelve_menu_panel__sect:nth-child(1) {
  animation-delay: .2s;
}
.twelve_menu_panel__links--display .twelve_menu_panel__sect:nth-child(2) {
  animation-delay: .25s;
}
.twelve_menu_panel__links--display .twelve_menu_panel__sect:nth-child(3) {
  animation-delay: .3s;
}
.twelve_menu_panel__links--display .twelve_menu_panel__sect:nth-child(4) {
  animation-delay: .35s;
}
.twelve_menu_panel__links--display .twelve_menu_panel__sect:nth-child(5) {
  animation-delay: .4s;
}
.twelve_menu_panel__links--display .twelve_menu_panel__sect:nth-child(6) {
  animation-delay: .45s;
}
.twelve_menu_panel__links--display .twelve_menu_panel__sect:nth-child(7) {
  animation-delay: .5s;
}
.twelve_menu_panel__links--display .twelve_menu_panel__sect:nth-child(8) {
  animation-delay: .55s;
}
.twelve_menu_panel__link{
  color: var(--color-pink);
  font-weight: 300;
  transition:.2s ease-out;
  line-height: 175%;
}
.twelve_menu_panel__link:hover {
  color: var(--color-purple);
}

@keyframes TwelveFadeIn {
  0% {
    transform: translate(0, 30px);
    opacity: 0;
  }
  85% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0px);
  }
}

/*======================================
=              MENU BUTTON             =
======================================*/
.twelve_menu_button{
  position: fixed;
  top: 7px;
  right: 14px;
  width: 60px;
  height: 64px;
  cursor: pointer;
  transition: .25s ease-out;
  z-index: 10;
}
.twelve_menu_button--stream {
  top: 0px;
  right: 10px;
}
.twelve_menu_button__container{
  background: transparent;
  color: #000;
}
.twelve_menu_button__line {
  background-color: var(--color-black);
  height: 2px;
  position: absolute;
  left: 18px;
  transition:.25s ease-out;
  transform: rotate(0deg);
}
.twelve_menu_button--stream .twelve_menu_button__line {
  background-color: var(--color-pink);
}
.twelve_menu_button:hover .twelve_menu_button__line{
  background-color: var(--color-purple);
}
.twelve_menu_button--scrolled .twelve_menu_button__line {
  background-color: var(--color-pink);
}
.twelve_menu_button--scrolled:hover .twelve_menu_button__line {
  background-color: var(--color-purple);
}
.twelve_menu_button__line:nth-child(1) {
  width: 28px;
  top: 12px;
}
.twelve_menu_button__line:nth-child(2) {
  width: 28px;
  top: 19px;
}
.twelve_menu_button__line:nth-child(3) {
  width: 18px;
  top: 26px;
}
.twelve_menu_button__container--displayed .twelve_menu_button__line:nth-child(1) {
  top: 20px;
  transform: rotate(45deg);
  background-color: var(--color-purple);
}
.twelve_menu_button__container--displayed .twelve_menu_button__line:nth-child(2) {
  top: 20px;
  transform: rotate(-45deg);
  background-color: var(--color-purple);
}
.twelve_menu_button__container--displayed .twelve_menu_button__line:nth-child(3) {
  width: 0px;
}

.mobile_menu_social_icons {
  transition:.2s ease-out;
  line-height: 175%;
  width: 100%;
  display: none;
}
.mobile_menu_social_icon {
  fill: var(--color-pink);
  vertical-align:middle;
  margin: 14px;
}
@media screen and (max-width: 768px){
  .mobile_menu_social_icons {
    display: flex;
    flex-wrap: wrap;
  }
  .mobile_menu_social_icon:hover {
    color: var(--color-purple);
  }
}

/*======================================
=             STREAM PAGE             =
======================================*/
.twelve_menu_button--stream {
  height: 40px;
}
.twelve_menu_button--stream .twelve_menu_button__container {
  height: 40px;
}
.twelve_menu_button--stream .twelve_menu_button__line:nth-child(1) {
  top: 12px;
}
.twelve_menu_button--stream .twelve_menu_button__line:nth-child(2) {
  top: 20px;
}
.twelve_menu_button--stream .twelve_menu_button__line:nth-child(3) {
  top: 28px;
}
.twelve_menu_button--stream .twelve_menu_button__container--displayed .twelve_menu_button__line:nth-child(1) {
  top: 18px;
  transform: rotate(45deg);
}
.twelve_menu_button--stream .twelve_menu_button__container--displayed .twelve_menu_button__line:nth-child(2) {
  top: 18px;
  transform: rotate(-45deg);
}


.menu_page__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.menu_page__overlay--display {
  pointer-events: auto;
}