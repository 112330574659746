.stream_wrapper {
  width: 100%;
  height: calc(100vh - 40px);
  display: flex;
  position: relative;
  flex-direction: row;
  margin-top: 40px;
  overflow: hidden;
}
.stream_wrapper--reverse {
  flex-direction: row-reverse;
}
.stream_bigscreen {
  width: calc(100vw - 340px);
}
.stream_chat {
  width: 340px;
  display: flex;
  flex-direction: column;
}
.stream_chat__top {
  height: 40px;
  width: 100%;
  background-color: #16191e;
  color: #ddd;
  line-height: 40px;
  display: flex;
  justify-content: flex-end;
}
.stream_chat__bottom {
  height: calc(100% - 40px);
  width: 100%;
}
.stream_chat__icon {
  cursor: pointer;
  transition:.2s ease-out;
  fill: var(--color-pink);
}
.stream_chat__icon:hover {
  fill: var(--color-teal-dark);
}
.stream_chat__icon svg {
  padding: 0px 7px;
}
.stream_wrapper--resize .stream_bigscreen {
  width: 100%;
}
.stream_wrapper--resize .stream_chat{
  display: none;
}
.stream_wrapper--hidden {
  display: none;
}
@media screen and (max-width: 992px) {
  .stream_wrapper {
    flex-direction: column;
  }
  .stream_bigscreen {
    width: 100%;
    height: 50%;
  }
  .stream_chat {
    width: 100%;
    height: 50%;
  }
  .stream_chat__top {
    display: none;
  }
  .stream_chat__bottom {
    height: 100%;
  }
}

.stream_chat_embed {
  height: 100vh;
  width: 100vw;
}