:root {
  --color-teal: #28e5ca;
  --color-teal-dark: #04858e;
	--color-grey-light: #ddd;
	--color-white: #fff;
	--color-black: #000;
  --color-black-opaque: rgba(17,17,17,1);
  --color-lavender-light: #f1e0ff;
  --color-lavender-transparent: rgba(241, 224, 255, .9);
  --color-purple: #b967ff;
	/*--color-pink: #ff71ce;*/
	--color-pink: #00e2ba;
	--color-pink-transparent: rgba(161, 185, 181, 0.9);
	--color-pink-light: rgba(0,226,186,.2);
	--color-green: #05ffa1;
	--time-anim: 4s;
	--delay-anim: 2s;
	--font-size--small: 1rem;
	--font-size--medium: 1.2rem;
	--font-size--large: 2rem;
	--transition-medium: .2s ease-in;
	--container-spacing: .5rem;
	--shadow-standard:   0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);
}
.App {
	font-family: 'Roboto Condensed', Helvetica;
}
html, body {
	margin: 0px;
}
a {
	text-decoration: none;
	transition: var(--transition-medium);
}
::selection {
  background: #b967ff;
}
::-moz-selection {
  background: #b967ff;
}
/* scrollbar-width scrollbar-color is just for firefox */
body * {
  scrollbar-width: thin;
  scrollbar-color:  #f1f1f1 #888;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bbb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.preload_images {
  position: fixed;
  width: 0;
  height: 0;
  overflow:hidden;
}
.main_background {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  pointer-events: none;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}
/*
.main_background--blur {
	filter: blur(6px);
}
*/
.main_background--hidden {
  display: none;
}

.glitch {
	transform: translate3d(0,0,0) scale3d(1,1,1);
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	animation-name: glitch-anim-text;
	animation-duration: var(--time-anim);
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.2);
}

@keyframes glitch-anim-text {
	0% {
		transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1);
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	2% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}
	4% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}
	5% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}
	6% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	7% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	8% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	9% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
	}
	9.9% {
		transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1);
	}
	10%, 100% {
		transform: translate3d(0,0,0) scale3d(1,1,1);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	}
}

.splash_divet__wrapper {
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50px;
  width: 100%;
}
.splash_divet{
  fill: var(--color-pink);
  height:9vw;
  cursor: pointer;
  transition: .2s ease;
}
.splash_divet:hover {
  fill: var(--color-purple);
}