/*======================================
=               LIGHTBOX               =
======================================*/

.lightbox__wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.9);
  pointer-events: none;
  z-index: 5;
}
.lightbox__wrapper--display {
  display: flex;
  pointer-events: auto;
}
.lightbox__image {
  max-height: 100vh;
  max-width: 100%;
  padding: 14px;
  box-sizing: border-box;
}
.lightbox__close {
  color: #fff;
  position: fixed;
  top: 70px;
  right: 30px;
  font-size: 36px;
  cursor: pointer;
  fill: var(--color-pink);
  transition: .2s ease;
}
.lightbox__close:hover {
  fill: var(--color-purple);
}
@media screen and (max-width: 768px) {
  .lightbox__close {
    right: 20px;
  }
}

.about__wrapper {
  margin-top: 2rem;
}
.about_object__wrapper {
  padding: 0px 2rem;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: var(--container-spacing);
}
.about_object {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background-color: rgba(231,252,248,.8);
  color: var(--color-black);
  position: relative;
  overflow: hidden;
  height: 300px;
  align-items: center;
  box-shadow: var(--shadow-standard);
}
.about_object:after {
  content: '';
  height: 0px;
  width: 100%;
  position: absolute;
  left: -10%;
  top: 0px;
  border-top: 300px solid var(--color-pink);
  border-right: 150px solid transparent;
  transition: .6s ease-in;
}
.about_object--display:after {
  transform:  translateX(-80%);
}

.about_object__image__wrapper {
  flex: 0 0 25%;
  padding: 24px;
  height: 100%;
  box-sizing: border-box;
}
.about_object__image {
  height: 100%;
  overflow: hidden;
}
.about_object img {
  cursor: pointer;
  height: 100%;
  transition: .2s ease-in;
}
.about_object img:hover {
  transform: scale(1.05, 1.05);
}
.about_object__text {
  flex: 0 0 50%;
  text-align: center;
  font-size: var(--font-size--medium);
  padding: 24px 10%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.about_object__text a {
  color: var(--color-pink);
  transition: var(--transition-medium);
}
.about_object__text a:hover {
  color: var(--color-purple);
}
@media screen and (max-width: 992px) {
  .about_object:after,
  .about_object--filled:after {
    display: none;
  }
  .about_object__image__wrapper {
    flex: 0 0 50%;
  }
  .about_object {
    height: 425px;
  }
  .about_object img {
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 768px) {
  .about_object__wrapper {
    padding: 0 1rem 1rem 1rem;
    margin-bottom: 0;
  }
  .about_object__image__wrapper {
    padding: 24px 24px 0px 24px;
  }
  .about_object {
    flex-direction: column;
    height: auto;
  }
  .about_object img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}