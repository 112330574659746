.fourteen_panel__top {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: #fff;
  transform: translateY(100vh);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 99999;
  background-position: center top;
}
.fourteen_panel__top--hide {
  animation: thirtyTwoOverlayOpen 1s;
}
@keyframes thirtyTwoOverlayOpen {
  0% {
    transform: translateY(100vh);
    opacity: 1;
  }
  40% {
    transform: translateY(0px);
    opacity: 1;
  }
  60% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 0;
  }
}

